import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HttpClientModule,
  HttpRequest,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { PagesModule } from './pages/public-section/pages.module';
import { RestrictionSectionModule } from './pages/restriction-section/restriction-section.module';
import { TranslateModule } from '@ngx-translate/core';
import { BlockUIModule } from 'ng-block-ui';
import { BlockTemplateComponent } from './components/block-template/block-template.component';
import { ModalResponsiveComponent } from './components/modal-responsive/modal-responsive.module';
import { ModalResponsiveService } from './components/modal-responsive/modal-responsive.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BlockUIHttpModule } from 'ng-block-ui/http';
import { functionsGeneric } from './classes/functions';
import { OwlDateTimeIntl, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { PrivacityPoliciesComponent } from './pages/privacity-policies/privacity-policies.component';
const requestFilters = [{ method: 'GET', url: new RegExp(/api/) }];

// Needs to be exported for AOT
export function filter(req: HttpRequest<any>): boolean {
  return requestFilters.some(
    ({ method, url }: { method: string; url: RegExp }) => {
      return req.method === method && url.test(req.url);
    }
  );
}

export class DefaultIntl extends OwlDateTimeIntl {
  cancelBtnLabel = 'Cancelar';
  setBtnLabel = 'Aceptar';
};

export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};


@NgModule({
  declarations: [AppComponent, PrivacityPoliciesComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PagesModule,
    RestrictionSectionModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
      delayStop: 500
    }),
    BlockUIHttpModule.forRoot({
      blockAllRequestsInProgress: true,
      requestFilters: [filter],
    }),
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    
    ModalResponsiveService,
    functionsGeneric,
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'es' },
    { provide: OwlDateTimeIntl, useClass: DefaultIntl },
  ],
  

  entryComponents: [ModalResponsiveComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}


