import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacity-policies',
  templateUrl: './privacity-policies.component.html',
  styleUrls: ['./privacity-policies.component.scss']
})
export class PrivacityPoliciesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
